
























































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import {
  getAuth, signOut, setPersistence, signInWithEmailAndPassword, browserSessionPersistence,
} from 'firebase/auth';
import { User } from '@/types';

@Component
export default class LoginDialog extends Vue {
  @Prop({ type: String, default: null }) readonly initialEmail!: string | null

  dialog = false

  email = ''
  password = ''

  alert = false
  error = ''

  loading = false
  valid = false

  get value(): boolean {
    return this.$store.getters.loginDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'LoginDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      // Logout se l'utente è già loggato
      if (this.isLoggedIn) {
        const auth = getAuth();
        signOut(auth).then(() => {
          console.log(`${debugPrefix} - signOut() success!`); // eslint-disable-line no-console

          this.$store.dispatch('unselectUser');
        });
      }

      this.password = '';
      this.loading = false;

      if (this.$route.query.email && typeof this.$route.query.email === 'string') {
        this.email = this.$route.query.email;
        // this.$nextTick(() => this.$refs.password.focus());
      } else {
        // this.$nextTick(() => this.$refs.email.focus());
      }
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'LoginDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'login');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog non scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  @Watch('initialEmail')
  onInitialEmailChanged(val: string | null, oldVal: string | null) {
    if (oldVal === null && val !== null) {
      this.email = val;
    }
  }

  onLogin() {
    const debugPrefix = 'LoginDialog: onLogin()';
    console.log(debugPrefix); // eslint-disable-line no-console

    const form = this.$refs.form as Vue & { validate: () => boolean };

    if (form.validate()) {
      this.loading = true;

      const auth = getAuth();

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          console.log(`${debugPrefix} - setPersistence() success!`); // eslint-disable-line no-console

          return signInWithEmailAndPassword(auth, this.email, this.password)
            .then((user) => {
              console.log(`${debugPrefix} - signInWithEmailAndPassword() success!`); // eslint-disable-line no-console
              console.log(user); // eslint-disable-line no-console

              this.dialog = false;
              this.loading = false;

              // eslint-disable-next-line @typescript-eslint/no-empty-function
              this.$router.replace({ name: 'Map' }, () => {});

              this.$store.dispatch('openDialog', 'muHives');
            })
            .catch((error) => {
              console.log(`${debugPrefix} - signInWithEmailAndPassword() error =`, error); // eslint-disable-line no-console

              this.loading = false;

              switch (error.code) {
                case 'auth/invalid-email':
                  this.error = 'L\'indirizzo email non è nel formato corretto';
                  break;

                case 'auth/user-not-found':
                  this.error = 'Non esiste un utente con questo indirizzo email';
                  break;

                case 'auth/wrong-password':
                  this.error = 'La password non è corretta';
                  break;

                default:
                  this.error = `Errore sconosciuto (${error.code})`;
                  break;
              }

              this.alert = true;
            });
        })
        .catch((error) => {
          console.log(`${debugPrefix} - setPersistence() error =`, error); // eslint-disable-line no-console
        });
    }
  }

  onReset() {
    const debugPrefix = 'LoginDialog: onReset()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'reset');
  }

  onSignup() {
    const debugPrefix = 'LoginDialog: onSignup()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'signup');
  }

  onClose() {
    this.dialog = false;
  }
}
